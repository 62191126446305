<template>
  <div class="blogger-orgin">
    <Pullrefresh @onLoad="onLoad" :loading="loading" :finished="finished" @onRefresh="onRefresh" :refreshing="refreshing" :isNoData="isNoData" :firstLoading="firstLoading">
      <div class="blogger-orgin-box">
        <div class="blogger-orgin-item" v-for="(item,index) in mediaInfos" :key="index" @click="onJumpVideo(item)">
          <div class="cover-box">
            <ImgDecypt :src="item.videoCover" class="blogger-orgin-cover" />
            <div class="video-info-timers flex-between">
              <div class="left">
                <span class="left-play"></span>{{item.watchTimes|watchCount}}
              </div>
              <div class="right">{{item.playTime|videotime}}</div>
            </div>
          </div>
          <h2>{{item.content}}</h2>
          <div class="line"></div>
          <CoveLabel class="cust_label" :item="item" isMax="true" />
          <div class="video-info">
            <div class="video-left">
              <div class="video-time">完整版{{item.playTime | videotime}}</div>
              <div class="video-tag">
                <span v-for="(tag,index) in item.flags" :key="index">
                  #{{tag}}
                </span>
              </div>
            </div>
            <div class="sell-right">
              <svg-icon :iconClass="item.isBuy || (item.fansGroup && item.fansGroup.isMember )?'icn_player' :'shopCard' " class="shopCard"></svg-icon>
            </div>
          </div>
        </div>
      </div>
    </Pullrefresh>
  </div>
</template>
<script>
import { getUseDetail } from "@/api/on_demand.js";
import Pullrefresh from "@/views/widget/PullRefresh.vue";
import CoveLabel from "@/components/CoveLabel/index.vue";
import { jumpVideo } from "@/utils/index.js";
export default {
  components: {
    Pullrefresh,
    CoveLabel,
  },
  data() {
    return {
      loading: false, //下滑loading
      finished: false, //数据是否加载完毕
      refreshing: false, //下拉下载loading
      isNoData: false, //数据是否为空
      firstLoading: true, //是否第一次加载
      // umt 1：原创 2:动态视频 3：粉丝福利 4:动态帖子 0:无效的占位符
      req: {
        userID: 0,
        umt: 3,
        pageNum: 1,
        pageSize: 10,
      },
      mediaInfos: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    onLoad() {
      this.req.pageNum++;
      this.getData();
    },
    onRefresh() {
      this.refreshing = true;
      this.finished = false;
      this.req.pageNum = 1;
      this.mediaInfos = [];
      this.getData();
    },
    async getData() {
      this.loading = true;
      let req = this.req;
      req.userID = Number(this.$route.query.id);

      let ret = await this.$Api(getUseDetail, this.req);
      this.loading = false;
      this.refreshing = false;
      this.firstLoading = false;

      if (ret.code == 200 && ret.data && ret.data.mediaInfos) {
        let mediaInfos = ret.data.mediaInfos;
        this.mediaInfos = this.mediaInfos.concat(mediaInfos);
        if (this.mediaInfos.length > 0) {
          this.author = this.mediaInfos[0].author;
        }
        if (mediaInfos.length == 0 && this.req.pageNum == 1) {
          this.isNoData = true;
          return;
        }
        if (mediaInfos.length < this.req.pageSize) {
          this.finished = true;
        }
      } else {
        this.isNoData = true;
        return;
      }
    },
    onJumpVideo(item) {
      jumpVideo(item, 'bloggerDetails');
    },
  },
};
</script>
<style lang="scss" scoped>
.blogger-orgin {
  box-sizing: border-box;
  width: 100%;

  /deep/ .van-grid-item__content {
    padding: 0;
  }

  .blogger-orgin-box {
    display: grid;
    padding: 10px;
    grid-template-columns: 1fr;
    grid-column-gap: 10px;
    box-sizing: border-box;

    .blogger-orgin-item {
      position: relative;
      border-radius: 6px;
      background: #ededed;
      margin-bottom: 20px;

      .blogger-orgin-cover {
        width: 100%;
        height: 174px;
        overflow: hidden;
        border-radius: 6px;

        /deep/ img {
          border-radius: 6px;
          overflow: hidden;
        }
      }

      h2 {
        box-sizing: border-box;
        width: 360px;
        padding: 10px 10px 0 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
        color: #515151;
      }

      .video-info {
        display: flex;
        justify-content: space-between;
        margin: 10px;
        align-items: center;

        .video-left {
          display: flex;
          align-items: center;

          .video-tag {
            margin: 0 10px;
            width: 160px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            padding-right: 10px;
          }

          .video-time {
            padding: 4px 4px;
            background: $vermillion;
            color: #fff;
            border-radius: 2px;
          }
        }

        .sell-right {
          width: 40px;
          height: 23px;
          background: linear-gradient(to right, $red, $vermillionFour);
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;

          .shopCard {
            width: 25px;
            height: 15px;
          }
        }
      }
    }
  }
}

//线
.line {
  margin: 0 10px 0 10px;
  width: 100%;
  height: 1px;
  background: #e8e8e8;
}

//图标
.cust_label {
  position: absolute;
  top: 0;
  right: 0;
}

//播放次数 时间
.cover-box {
  position: relative;
}

.video-info-timers {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  align-items: center;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
  padding: 10px 15px;
  box-sizing: border-box;
  font-size: 12px;
  color: #ffffff;

  .left {
    display: flex;
    align-items: center;

    .left-play {
      display: inline-block;
      margin-right: 5px;
      width: 15px;
      height: 15px;
      background: url("../../assets/png/play_icon.png") center center no-repeat;
      background-size: 100%;
    }
  }
}
</style>