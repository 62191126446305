<template>
  <div>
    <van-popup v-model="isfanShow" position="bottom" round @click-overlay="$emit('fanClose')" :close-on-click-overlay="false">
      <div class="fan-box">
        <div class="line"></div>
        <h2>加入粉丝团</h2>
        <div class="blogger-avatar">
          <ImgDecypt :src="fansGroup &&fansGroup.avatar" :round="true" class="avatar" />
          <img :src="avatarFrame" class="avatarFrame">
        </div>
        <p class="fan-name">{{fansGroup && fansGroup.name}}</p>
        <p class="fan-jon-money">加入粉丝团需要消耗 <span>{{fansGroup && fansGroup.joinMoney}}</span> 金币</p>
        <div class="fan-info">
          <p>粉丝团说明</p>
          <p class="fan-detail">加入粉丝团后每周享受博主粉丝的粉丝福利视频观看，博主往期发布的所有福利视频粉丝团成功无论加入早晚都可以观看，如果不是粉丝团成功则需要按照视频原价付费观看！</p>
        </div>
        <van-button color="#fe121e" class="join-btn" @click="joinFanBtn">确认加入</van-button>
      </div>
    </van-popup>
    <!-- loading -->
    <van-overlay :show="loading" class="myl flex-center">
      <van-loading type="spinner" color="#f21313" size="24px" vertical>正在购买，请稍等</van-loading>
    </van-overlay>
  </div>
</template>
<script>
import { joinFansGroup } from "@/api/on_demand.js";
export default {
  props: {
    isfanShow: {
      require: true,
      default: false,
    },
    fansGroup: {
      require: true,
    },
    logTypt: {
      type: String,
      default: 'user'
    }
  },
  data() {
    return {
      avatarFrame: require("@/assets/png/avatarFrame2.png"),
      loading: false,
    };
  },
  methods: {
    async joinFanBtn() {
      if (!this.fansGroup.id) {
        this.$toast("异常错误,加入失败");
        return;
      }
      let req = {
        fansGroupID: this.fansGroup.id,
        join: true,
      };
      this.loading = true;
      let ret = await this.$Api(joinFansGroup, req);
      this.loading = false;
      if (ret.code == 200) {
        this.$emit("fanSuc");
        return;
      }
      if (ret.code == 6010) {
        this.$toast(ret.tip || "余额不足");
        let vipGrap = {
          type: this.logTypt,
        }
        if (this.logTypt == 'video') {
          vipGrap.vInfo = this.fansGroup;
        }
        sessionStorage.setItem('vipGrap', JSON.stringify(vipGrap));
        this.$router.push("/wallet");
        return;
      }
      this.$toast(ret.tip || "加入失败");
    },
  },
};
</script>

<style lang="scss" scoped>
.myl {
  z-index: 2010;
}
.blogger-avatar {
  margin: -30px auto 0;
  width: 70px;
  height: 70px;
  padding: 5px;
  box-sizing: border-box;
  position: relative;
  .avatarFrame {
    position: absolute;
    top: -2px;
    left: 0;
    width: 70px;
    height: 70px;
  }
}
//粉丝团购买
.fan-box {
  text-align: center;
  border-radius: 8px 8px 0 0;
  .line {
    width: 100px;
    height: 5px;
    border-radius: 6px;
    background: #e4e4e4;
    margin: 10px auto;
  }
  h2 {
    padding: 10px 0 40px 0;
    font-size: 20px;
    color: #000;
  }
  .fan-name {
    width: 100%;
    padding: 10px 0;
  }
  .fan-info {
    background: #e4e4e4;
    padding: 10px;
    margin: 10px;
    line-height: 18px;
    border-radius: 8px;
    .fan-detail {
      text-align: left;
    }
  }
  .fan-jon-money {
    span {
      color: red;
    }
  }
  .join-btn {
    box-sizing: border-box;
    width: 90%;
    height: 36px;
    border-radius: 15px;
    margin: 10px 0 30px 0;
    font-size: 18px;
  }
}
</style>